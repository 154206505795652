// PostDetail.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FaClock, FaUsers, FaTachometerAlt, FaStar, FaUser, FaCalendar } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import { recipeService } from '../../services/recipeService';
import { tipService } from '../../services/tipService';
import './PostDetail.css';

const PostDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Determinar el tipo basado en la URL
  const isRecipe = location.pathname.startsWith('/receta/');
  const isTip = location.pathname.startsWith('/consejo/');

  useEffect(() => {
    const fetchPost = async () => {
      try {
        let fetchedPost = null;

        if (isRecipe) {
          fetchedPost = await recipeService.getRecipeBySlug(slug);
        } else if (isTip) {
          fetchedPost = await tipService.getTipBySlug(slug);
        }

        if (fetchedPost) {
          setPost(fetchedPost);
        } else {
          setError('Contenido no encontrado');
          navigate('/blog');
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        setError('Error al cargar el contenido');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug, navigate, isRecipe, isTip]);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar
        key={index}
        className={`star ${index < (rating || 0) ? 'filled' : 'empty'}`}
      />
    ));
  };

  if (loading) {
    return (
      <div className="recipe-loader">
        <div className="loader">Cargando...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="recipe-error">
        <h2>{error}</h2>
      </div>
    );
  }

  if (!post) return null;

  // Si es una receta
  if (isRecipe) {
    return (
      <div className="recipe-detail">
        <Helmet>
          <title>{post.title} - The Original Rub Hub</title>
          <meta name="description" content={post.metaDescription || post.description} />
        </Helmet>

        <div className="hero-section">
          <div className="hero-image" style={{ backgroundImage: `url(${post.mainImage.url})` }}>
            <div className="hero-overlay">
              <Container>
                <div className="hero-content">
                  <div className="categories">
                    <span className="category-tag">{post.category}</span>
                    <span className="category-tag">{post.subcategory}</span>
                  </div>
                  <h1>{post.title}</h1>
                </div>
              </Container>
            </div>
          </div>
        </div>

        <Container className="recipe-container">
          <div className="recipe-intro">
            <p className="recipe-description">{post.description}</p>
            
            <div className="recipe-meta">
              <div className="meta-item">
                <FaClock className="meta-icon"/>
                <span className="meta-label">Tiempo</span>
                <span className="meta-value">{post.cookTime} min</span>
              </div>
              <div className="meta-item">
                <FaUsers className="meta-icon"/>
                <span className="meta-label">Comensales</span>
                <span className="meta-value">{post.servings}</span>
              </div>
              <div className="meta-item">
                <FaTachometerAlt className="meta-icon"/>
                <span className="meta-label">Dificultad</span>
                <span className="meta-value">{post.difficulty}</span>
              </div>
              <div className="meta-item">
                <div className="rating-stars">
                  {renderStars(post.rating)}
                </div>
                <span className="meta-label">Valoración</span>
              </div>
            </div>
          </div>

          <Row className="recipe-content">
            <Col md={4}>
              <div className="ingredients-section">
                <h2>Ingredientes</h2>
                <p className="servings-info">Para {post.servings} personas</p>
                <ul className="ingredients-list">
                  {post.ingredients.map((ingredient, index) => (
                    <li key={index} className="ingredient-item">
                      <span className="ingredient-amount">
                        {ingredient.quantity} {ingredient.unit}
                      </span>
                      <span className="ingredient-name">{ingredient.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>

            <Col md={8}>
              <div className="steps-section">
                <h2>Preparación</h2>
                <div className="steps-list">
                  {post.steps.map((step, index) => (
                    <div key={index} className="step-item">
                      <div className="step-header">
                        <div className="step-number">Paso {index + 1}</div>
                      </div>
                      <p className="step-description">{step.description}</p>
                      {step.image && step.image.url && (
                        <div className="step-image-container">
                          <img 
                            src={step.image.url} 
                            alt={`Paso ${index + 1}`} 
                            className="step-image"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  // Si es un consejo
  if (isTip) {
    return (
      <div className="tip-detail">
        <Helmet>
          <title>{post.title} - The Original Rub Hub</title>
          <meta name="description" content={post.metaDescription || post.content} />
        </Helmet>

        <div className="post-hero" style={{ 
          backgroundImage: post.image ? `url(${post.image.url})` : 'none' 
        }}>
          <div className="post-hero-overlay">
            <Container>
              <h1 className="post-title">{post.title}</h1>
              <div className="post-meta-info">
                <span>
                  <FaCalendar /> {new Date(post.createdAt?.seconds * 1000).toLocaleDateString()}
                </span>
                {post.author && (
                  <span>
                    <FaUser /> {post.author}
                  </span>
                )}
              </div>
            </Container>
          </div>
        </div>

        <Container className="post-content-container">
          <Row>
            <Col lg={8} className="mx-auto">
              <div className="post-content">
                <div className="post-text" dangerouslySetInnerHTML={{ __html: post.content }} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return null;
};

export default PostDetail;