import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { tipService } from '../../services/tipService';
import { Card, Button, Row, Col, Pagination } from 'react-bootstrap';

const TipList = () => {
  const [tips, setTips] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tipsPerPage, setTipsPerPage] = useState(10);
  const [totalTips, setTotalTips] = useState(0);

  useEffect(() => {
    fetchTips();
  }, [currentPage, tipsPerPage]);

  const fetchTips = async () => {
    try {
      const result = await tipService.getPaginatedTips(currentPage, tipsPerPage);
      setTips(result.tips);
      setTotalTips(result.total);
    } catch (error) {
      console.error("Error fetching tips:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este consejo?')) {
      await tipService.deleteTip(id);
      fetchTips();
    }
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleTipsPerPageChange = (e) => {
    setTipsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Fecha no disponible';
    if (timestamp.seconds) {
      return new Date(timestamp.seconds * 1000).toLocaleDateString();
    }
    if (timestamp instanceof Date) {
      return timestamp.toLocaleDateString();
    }
    return 'Fecha inválida';
  };

  return (
    <div className="tip-list">
      <h2 className="mb-4">Consejos BBQ</h2>
      
      {tips.length === 0 ? (
        <p>Aún no hay consejos. ¡Añade uno nuevo!</p>
      ) : (
        <Row>
          {tips.map(tip => (
            <Col md={6} lg={4} key={tip.id} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>{tip.title}</Card.Title>
                  <Card.Text>{tip.content}</Card.Text>
                  <Card.Text>
                    <small className="text-muted">
                      Creado: {formatDate(tip.createdAt)}
                      <br />
                      Publicado: {tip.publishDate ? formatDate(new Date(tip.publishDate)) : 'No publicado'}
                    </small>
                  </Card.Text>
                  <div className="d-flex justify-content-end">
                    <Link to={`/admin/tip/${tip.id}`} className="btn btn-sm btn-info me-2">
                      <i className="bi bi-pencil"></i>
                    </Link>
                    <Button variant="danger" size="sm" onClick={() => handleDelete(tip.id)}>
                      <i className="bi bi-trash"></i>
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      <div className="d-flex justify-content-between align-items-center mt-4">
        <Pagination>
          {[...Array(Math.ceil(totalTips / tipsPerPage))].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>

        <select
          className="form-select"
          style={{ width: 'auto' }}
          value={tipsPerPage}
          onChange={handleTipsPerPageChange}
        >
          {[10, 20, 50, 100].map(number => (
            <option key={number} value={number}>
              Mostrar {number}
            </option>
          ))}
        </select>
      </div>

      <Link to="/admin/tip/new" className="btn btn-primary mt-4">Añadir Nuevo Consejo</Link>
    </div>
  );
};

export default TipList;