import { 
    getAuth, 
    signInWithEmailAndPassword, 
    signOut, 
    onAuthStateChanged 
  } from 'firebase/auth';
  import { doc, getDoc } from 'firebase/firestore';
  import { db } from './firebaseConfig';
  
  const auth = getAuth();
  
  export const authService = {
    async login(email, password) {
      try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        const userDoc = await getDoc(doc(db, 'admins', result.user.uid));
        
        if (!userDoc.exists()) {
          await signOut(auth);
          throw new Error('Usuario no autorizado');
        }
        
        return result.user;
      } catch (error) {
        console.error('Error en login:', error);
        throw new Error('Error de autenticación');
      }
    },
  
    async logout() {
      try {
        await signOut(auth);
      } catch (error) {
        console.error('Error en logout:', error);
        throw new Error('Error al cerrar sesión');
      }
    },
  
    onAuthStateChanged(callback) {
      return onAuthStateChanged(auth, callback);
    },
  
    getCurrentUser() {
      return auth.currentUser;
    },
  
    async isAdmin() {
      const user = this.getCurrentUser();
      if (!user) return false;
      
      const userDoc = await getDoc(doc(db, 'admins', user.uid));
      return userDoc.exists();
    }
  };