import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/admin.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-quill/dist/quill.snow.css';

// Componentes de Layout
import Layout from './components/Layout';

// Componentes Públicos
import BBQHistory from './components/publics/BBQHistory';
import Blog from './components/publics/Blog';
import PostDetail from './components/publics/PostDetail';

// Componentes de Administración
import AdminDashboard from './components/admins/AdminDashboard';
import RecipeList from './components/admins/RecipeList';
import RecipeForm from './components/admins/RecipeForm';
import TipList from './components/admins/TipList';
import TipForm from './components/admins/TipForm';

// Componentes de Autenticación
import Login from './components/auth/Login';
import ProtectedRoute from './components/auth/ProtectedRoute';
import HiddenAdminAccess from './components/auth/HiddenAdminAccess';

function App() {
  return (
    <Router>
      <HiddenAdminAccess />
      <Layout>
        <Routes>
          {/* Rutas Públicas */}
          <Route path="/" element={<BBQHistory />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/receta/:slug" element={<PostDetail source="recipe" />} />
          <Route path="/consejo/:slug" element={<PostDetail source="tip" />} />
          
          {/* Ruta de Login */}
          <Route path="/admin-login" element={<Login />} />
          
          {/* Rutas Protegidas de Administración */}
          <Route
            path="/admin"
            element={<Navigate to="/admin/dashboard" replace />}
          />
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/recipes"
            element={
              <ProtectedRoute>
                <RecipeList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/recipe/new"
            element={
              <ProtectedRoute>
                <RecipeForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/recipe/:id"
            element={
              <ProtectedRoute>
                <RecipeForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/tips"
            element={
              <ProtectedRoute>
                <TipList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/tip/new"
            element={
              <ProtectedRoute>
                <TipForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/tip/:id"
            element={
              <ProtectedRoute>
                <TipForm />
              </ProtectedRoute>
            }
          />
          {/* Ruta para manejar URLs no encontradas */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;