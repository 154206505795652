import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Card, InputGroup } from 'react-bootstrap';
import { recipeService } from '../../services/recipeService';
import { FaHamburger, FaDrumstickBite, FaFish, FaCarrot, FaWineBottle, FaFireAlt, FaSmog, FaUtensils, FaTrash } from 'react-icons/fa';

const RecipeForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState({
    title: '',
    description: '',
    servings: '',
    cookTime: '',
    difficulty: '',
    ingredients: [{ quantity: '', unit: '', name: '' }],
    steps: [{ description: '', image: null, imageUrl: '' }],
    mainImage: null,
    mainImageUrl: '',
    publishDate: new Date().toISOString().split('T')[0],
    category: '',
    subcategory: ''
  });

  const [seoData, setSeoData] = useState({
    metaTitle: '',
    metaDescription: ''
  });

  const measurementUnits = recipeService.getMeasurementUnits();
  const categories = recipeService.getCategories();

  useEffect(() => {
    if (id) {
      const fetchRecipe = async () => {
        const fetchedRecipe = await recipeService.getRecipeById(id);
        const formattedIngredients = fetchedRecipe.ingredients.map(ing => {
          if (typeof ing === 'string') {
            return { quantity: '', unit: '', name: ing };
          }
          return ing;
        });
        
        setRecipe({
          ...fetchedRecipe,
          ingredients: formattedIngredients,
          mainImageUrl: fetchedRecipe.mainImage ? fetchedRecipe.mainImage.url : '',
          mainImage: null,
          steps: fetchedRecipe.steps.map(step => ({
            ...step,
            imageUrl: step.image ? step.image.url : '',
            image: null
          })),
          publishDate: fetchedRecipe.publishDate ? new Date(fetchedRecipe.publishDate).toISOString().split('T')[0] : ''
        });
        setSeoData({
          metaTitle: fetchedRecipe.metaTitle || fetchedRecipe.title,
          metaDescription: fetchedRecipe.metaDescription || fetchedRecipe.description.substring(0, 160)
        });
      };
      fetchRecipe();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecipe(prevRecipe => ({ ...prevRecipe, [name]: value }));
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...recipe.ingredients];
    newIngredients[index] = {
      ...newIngredients[index],
      [field]: value
    };
    setRecipe(prevRecipe => ({ ...prevRecipe, ingredients: newIngredients }));
  };

  const handleStepChange = (index, field, value) => {
    const newSteps = [...recipe.steps];
    if (field === 'image' && value instanceof File) {
      newSteps[index] = { 
        ...newSteps[index], 
        [field]: value,
        imageUrl: URL.createObjectURL(value)
      };
    } else {
      newSteps[index] = { ...newSteps[index], [field]: value };
    }
    setRecipe(prevRecipe => ({ ...prevRecipe, steps: newSteps }));
  };

  const handleMainImageChange = (e) => {
    if (e.target.files[0]) {
      setRecipe(prevRecipe => ({ 
        ...prevRecipe, 
        mainImage: e.target.files[0],
        mainImageUrl: URL.createObjectURL(e.target.files[0])
      }));
    }
  };

  const handleSeoChange = (e) => {
    const { name, value } = e.target;
    setSeoData(prevData => ({ ...prevData, [name]: value }));
  };

  const addIngredient = () => {
    setRecipe(prevRecipe => ({
      ...prevRecipe,
      ingredients: [...prevRecipe.ingredients, { quantity: '', unit: '', name: '' }]
    }));
  };

  const removeIngredient = (index) => {
    if (recipe.ingredients.length > 1) {
      setRecipe(prevRecipe => ({
        ...prevRecipe,
        ingredients: prevRecipe.ingredients.filter((_, i) => i !== index)
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const recipeWithSeo = { ...recipe, ...seoData };
    try {
      if (id) {
        await recipeService.updateRecipe(id, recipeWithSeo);
      } else {
        await recipeService.createRecipe(recipeWithSeo);
      }
      navigate('/admin/recipes');
    } catch (error) {
      console.error('Error al guardar la receta:', error);
      // Aquí podrías añadir un manejo de errores más elaborado
    }
  };

  return (
    <Container className="my-5">
      <h2 className="mb-4">{id ? 'Editar Receta' : 'Nueva Receta'}</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={8}>
            <Card className="mb-4">
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Título</Form.Label>
                  <Form.Control type="text" name="title" value={recipe.title} onChange={handleChange} required />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control as="textarea" rows={3} name="description" value={recipe.description} onChange={handleChange} required />
                </Form.Group>

                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Comensales</Form.Label>
                      <Form.Control type="number" name="servings" value={recipe.servings} onChange={handleChange} required />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Tiempo de Cocina (minutos)</Form.Label>
                      <Form.Control type="number" name="cookTime" value={recipe.cookTime} onChange={handleChange} required />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Dificultad</Form.Label>
                      <Form.Select name="difficulty" value={recipe.difficulty} onChange={handleChange} required>
                        <option value="">Seleccionar...</option>
                        <option value="Fácil">Fácil</option>
                        <option value="Media">Media</option>
                        <option value="Difícil">Difícil</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>Categoría</Form.Label>
                  <Form.Select name="category" value={recipe.category} onChange={handleChange} required>
                    <option value="">Seleccionar categoría...</option>
                    {categories.map(cat => (
                      <option key={cat.id} value={cat.id}>{cat.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>

                {recipe.category && (
                  <Form.Group className="mb-3">
                    <Form.Label>Subcategoría</Form.Label>
                    <Form.Select name="subcategory" value={recipe.subcategory} onChange={handleChange} required>
                      <option value="">Seleccionar subcategoría...</option>
                      {categories.find(cat => cat.id === recipe.category)?.subcategories.map(subcat => (
                        <option key={subcat.id} value={subcat.id}>{subcat.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>Ingredientes</Form.Label>
                  {recipe.ingredients.map((ingredient, index) => (
                    <div key={index} className="mb-2">
                      <InputGroup>
                        <Form.Control
                          type="number"
                          step="0.01"
                          placeholder="Cantidad"
                          value={ingredient.quantity}
                          onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
                          style={{ width: '100px' }}
                        />
                        <Form.Select
                          value={ingredient.unit}
                          onChange={(e) => handleIngredientChange(index, 'unit', e.target.value)}
                          style={{ width: '150px' }}
                        >
                          <option value="">Unidad...</option>
                          {measurementUnits.map(unit => (
                            <option key={unit.value} value={unit.value}>
                              {unit.label}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control
                          type="text"
                          placeholder="Nombre del ingrediente"
                          value={ingredient.name}
                          onChange={(e) => handleIngredientChange(index, 'name', e.target.value)}
                        />
                        <Button 
                          variant="danger" 
                          onClick={() => removeIngredient(index)}
                          disabled={recipe.ingredients.length === 1}
                        >
                          <FaTrash />
                        </Button>
                      </InputGroup>
                    </div>
                  ))}
                  <Button variant="secondary" onClick={addIngredient} className="mt-2">
                    Añadir Ingrediente
                  </Button>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Pasos</Form.Label>
                  {recipe.steps.map((step, index) => (
                    <Card key={index} className="mb-3">
                      <Card.Body>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={step.description}
                          onChange={(e) => handleStepChange(index, 'description', e.target.value)}
                          className="mb-2"
                        />
                        <Form.Control
                          type="file"
                          onChange={(e) => handleStepChange(index, 'image', e.target.files[0])}
                          className="mb-2"
                        />
                        {step.imageUrl && (
                          <img src={step.imageUrl} alt={`Paso ${index + 1}`} style={{maxWidth: '200px', marginBottom: '10px'}} />
                        )}
                      </Card.Body>
                    </Card>
                  ))}
                  <Button variant="secondary" onClick={() => setRecipe(prevRecipe => 
                    ({ ...prevRecipe, steps: [...prevRecipe.steps, { description: '', image: null, imageUrl: '' }] }))}>
                    Añadir Paso
                  </Button>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Imagen Principal</Form.Label>
                  <Form.Control type="file" onChange={handleMainImageChange} />
                  {recipe.mainImageUrl && (
                    <img src={recipe.mainImageUrl} alt="Imagen principal" className="img-fluid mt-2" />
                  )}
                </Form.Group>
                
                <Form.Group className="mb-3">
                  <Form.Label>Valoración</Form.Label>
                  <Form.Select name="rating" value={recipe.rating} onChange={handleChange} required>
                    <option value={5}>5 estrellas</option>
                    <option value={4}>4 estrellas</option>
                    <option value={3}>3 estrellas</option>
                    <option value={2}>2 estrellas</option>
                    <option value={1}>1 estrella</option>
                  </Form.Select>
                </Form.Group>
                
                <Form.Group className="mb-3">
                  <Form.Label>Fecha de Publicación</Form.Label>
                  <Form.Control type="date" name="publishDate" value={recipe.publishDate} onChange={handleChange} required />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Título Meta (SEO)</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="metaTitle" 
                    value={seoData.metaTitle} 
                    onChange={handleSeoChange} 
                    maxLength="60"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Descripción Meta (SEO)</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    name="metaDescription" 
                    value={seoData.metaDescription} 
                    onChange={handleSeoChange} 
                    maxLength="160"
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Button type="submit" variant="primary" className="me-2">
          {id ? 'Actualizar' : 'Crear'} Receta
        </Button>
        <Button variant="secondary" onClick={() => navigate('/admin/recipes')}>
          Cancelar
        </Button>
      </Form>
    </Container>
  );
};

export default RecipeForm;