import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from './firebaseConfig';
import imageCompression from 'browser-image-compression';

export const imageService = {
  async uploadAndOptimizeImage(file, path, altText) {
    if (!file) return null;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const fileName = `${Date.now()}_${file.name.replace(/\s+/g, '-').toLowerCase()}`;
      const storageRef = ref(storage, `${path}/${fileName}`);
      await uploadBytes(storageRef, compressedFile);
      const url = await getDownloadURL(storageRef);
      return { url, alt: altText || fileName.split('_')[1].split('.')[0] };
    } catch (error) {
      console.error("Error al procesar la imagen:", error);
      throw error;
    }
  },

  async deleteImage(imageData) {
    if (!imageData) return;
    
    try {
      let imagePath;
      
      // Si imageData es un objeto con la ruta de almacenamiento
      if (typeof imageData === 'object' && imageData.storagePath) {
        imagePath = imageData.storagePath;
      }
      // Si imageData es un objeto con url
      else if (typeof imageData === 'object' && imageData.url) {
        const urlObj = new URL(imageData.url);
        const encodedPath = urlObj.pathname.split('/o/')[1];
        if (encodedPath) {
          imagePath = decodeURIComponent(encodedPath.split('?')[0]);
        }
      }
      // Si imageData es una string (url)
      else if (typeof imageData === 'string' && imageData.includes('firebase')) {
        const urlObj = new URL(imageData);
        const encodedPath = urlObj.pathname.split('/o/')[1];
        if (encodedPath) {
          imagePath = decodeURIComponent(encodedPath.split('?')[0]);
        }
      }

      if (imagePath) {
        const imageRef = ref(storage, imagePath);
        await deleteObject(imageRef);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      // No lanzamos el error para permitir que continúe el proceso de eliminación
    }
  },

  async updateImage(oldImage, newImage, folder, name) {
    try {
      // Si hay una imagen nueva
      if (newImage instanceof File) {
        // Primero intentamos eliminar la imagen antigua
        if (oldImage) {
          await this.deleteImage(oldImage);
        }
        // Subimos la nueva imagen
        return await this.uploadAndOptimizeImage(newImage, folder, name);
      }
      // Si no hay imagen nueva, devolvemos la antigua
      return oldImage;
    } catch (error) {
      console.error('Error updating image:', error);
      throw error;
    }
  }
};