import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { authService } from '../../services/authService';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await authService.logout();
      navigate('/');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  return (
    <Button variant="outline-light" onClick={handleLogout} size="sm">
      <i className="bi bi-box-arrow-right me-2"></i>
      Cerrar Sesión
    </Button>
  );
};

export default LogoutButton;