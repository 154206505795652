import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, getDoc, query, orderBy, limit, serverTimestamp, where } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { imageService } from './imageService';
import slugify from 'slugify';

const COLLECTION_NAME = 'tips';

export const tipService = {
  async getPaginatedTips(page, perPage) {
    const tipsRef = collection(db, COLLECTION_NAME);
    const q = query(
      tipsRef,
      orderBy('createdAt', 'desc'),
      limit(perPage)
    );

    const snapshot = await getDocs(q);
    const tips = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const totalQuery = query(tipsRef);
    const totalSnapshot = await getDocs(totalQuery);
    const total = totalSnapshot.size;

    return { tips, total };
  },

  async getTipById(id) {
    const docRef = doc(db, COLLECTION_NAME, id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
  },

  async getTipBySlug(slug) {
    const tipsRef = collection(db, COLLECTION_NAME);
    const q = query(tipsRef, where("slug", "==", slug));
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
      const doc = snapshot.docs[0];
      return { id: doc.id, ...doc.data() };
    }
    return null;
  },

  async createTip(tipData) {
    const imageUrl = await imageService.uploadAndOptimizeImage(tipData.image, 'tips', tipData.title);

    const slug = slugify(tipData.title, { lower: true, strict: true });
    const metaDescription = tipData.content.substring(0, 160);
    
    const newTip = { 
      ...tipData,
      slug,
      image: imageUrl,
      metaTitle: tipData.metaTitle || tipData.title,
      metaDescription: tipData.metaDescription || metaDescription,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };
    const docRef = await addDoc(collection(db, COLLECTION_NAME), newTip);
    return { id: docRef.id, ...newTip };
  },

  async updateTip(id, tipData) {
    const oldTip = await this.getTipById(id);
    
    const imageUrl = await imageService.updateImage(oldTip.image, tipData.image, 'tips', tipData.title);

    const slug = slugify(tipData.title, { lower: true, strict: true });
    const metaDescription = tipData.content.substring(0, 160);

    const updatedTip = { 
      ...tipData,
      slug,
      image: imageUrl,
      metaTitle: tipData.metaTitle || tipData.title,
      metaDescription: tipData.metaDescription || metaDescription,
      updatedAt: serverTimestamp(),
    };
    await updateDoc(doc(db, COLLECTION_NAME, id), updatedTip);
    return { id, ...updatedTip };
  },

  async deleteTip(id) {
    const tip = await this.getTipById(id);
    await imageService.deleteImage(tip.image);
    await deleteDoc(doc(db, COLLECTION_NAME, id));
  }
};