import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from './firebaseConfig';

export const getAllPosts = async () => {
  try {
    // Obtener recetas
    const recipesQuery = query(
      collection(db, 'recipes'),
      orderBy('createdAt', 'desc')
    );
    const recipesSnapshot = await getDocs(recipesQuery);
    const recipes = recipesSnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      collection: 'recipes'
    }));

    // Obtener consejos
    const tipsQuery = query(
      collection(db, 'tips'),
      orderBy('createdAt', 'desc')
    );
    const tipsSnapshot = await getDocs(tipsQuery);
    const tips = tipsSnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      collection: 'tips'
    }));

    // Combinar y ordenar por fecha
    const allPosts = [...recipes, ...tips].sort((a, b) => 
      b.createdAt?.seconds - a.createdAt?.seconds
    );

    return allPosts;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
};