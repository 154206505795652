import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { authService } from '../services/authService';
import LogoutButton from './auth/LogoutButton';
import './header.css';

const Header = () => {
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);
  const isAdminRoute = location.pathname.startsWith('/admin');

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged(async (user) => {
      if (user) {
        const adminStatus = await authService.isAdmin();
        setIsAdmin(adminStatus);
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Renderiza el header administrativo
  if (isAdminRoute && isAdmin) {
    return (
      <header className="bbq-header admin-header">
        <Container>
          <Row className="align-items-center">
            <Col md={4}>
              <h1 className="bbq-title">The Original Rub Hub Admin</h1>
            </Col>
            <Col md={8}>
              <nav>
                <ul className="bbq-nav">
                  <li>
                    <Link to="/" className="public-link">
                      Ver Sitio
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/admin/dashboard" 
                      className={location.pathname === '/admin/dashboard' ? 'active' : ''}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/admin/recipes" 
                      className={location.pathname.startsWith('/admin/recipes') ? 'active' : ''}
                    >
                      Gestionar Recetas
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/admin/tips" 
                      className={location.pathname.startsWith('/admin/tips') ? 'active' : ''}
                    >
                      Gestionar Tips
                    </Link>
                  </li>
                  <li>
                    <LogoutButton />
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }

  // Renderiza el header público
  return (
    <header className="bbq-header">
      <Container>
        <Row className="align-items-center">
          <Col md={4}>
            <h1 className="bbq-title">The Original Rub Hub</h1>
          </Col>
          <Col md={8}>
            <nav>
              <ul className="bbq-nav">
                <li>
                  <Link 
                    to="/" 
                    className={location.pathname === '/' ? 'active' : ''}
                  >
                    Historia BBQ
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/blog" 
                    className={location.pathname.startsWith('/blog') ? 'active' : ''}
                  >
                    Blog
                  </Link>
                </li>
                {isAdmin && (
                  <li>
                    <Link 
                      to="/admin/dashboard" 
                      className="admin-link"
                    >
                      Admin
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;