import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { tipService } from '../../services/tipService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TipForm.css';

const TipForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [tip, setTip] = useState({
    title: '',
    content: '',
    image: null,
    imageUrl: '',
    publishDate: new Date().toISOString().split('T')[0]
  });

  const [seoData, setSeoData] = useState({
    metaTitle: '',
    metaDescription: ''
  });

  // Configuración del editor
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'blockquote', 'code-block'],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'blockquote', 'code-block',
    'color', 'background'
  ];

  useEffect(() => {
    if (id) {
      const fetchTip = async () => {
        const fetchedTip = await tipService.getTipById(id);
        setTip({
          ...fetchedTip,
          imageUrl: fetchedTip.image ? fetchedTip.image.url : '',
          image: null,
          publishDate: fetchedTip.publishDate ? new Date(fetchedTip.publishDate).toISOString().split('T')[0] : ''
        });
        setSeoData({
          metaTitle: fetchedTip.metaTitle || fetchedTip.title,
          metaDescription: fetchedTip.metaDescription || stripHtml(fetchedTip.content).substring(0, 160)
        });
      };
      fetchTip();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTip(prevTip => ({ ...prevTip, [name]: value }));
  };

  const handleEditorChange = (content) => {
    setTip(prevTip => ({ ...prevTip, content }));
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setTip(prevTip => ({
        ...prevTip,
        image: e.target.files[0],
        imageUrl: URL.createObjectURL(e.target.files[0])
      }));
    }
  };

  const handleSeoChange = (e) => {
    const { name, value } = e.target;
    setSeoData(prevData => ({ ...prevData, [name]: value }));
  };

  // Función auxiliar para quitar HTML para la meta descripción
  const stripHtml = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tipWithSeo = { ...tip, ...seoData };
    if (id) {
      await tipService.updateTip(id, tipWithSeo);
    } else {
      await tipService.createTip(tipWithSeo);
    }
    navigate('/admin/tips');
  };

  return (
    <form onSubmit={handleSubmit} className="tip-form">
      <h2>{id ? 'Editar Consejo' : 'Nuevo Consejo'}</h2>
      
      <div className="form-group">
        <label>Título</label>
        <input 
          type="text" 
          name="title" 
          value={tip.title} 
          onChange={handleChange} 
          className="form-control" 
          required 
        />
      </div>

      <div className="form-group">
        <label>Contenido</label>
        <ReactQuill
          value={tip.content}
          onChange={handleEditorChange}
          modules={modules}
          formats={formats}
          className="editor-container"
        />
      </div>

      <div className="form-group">
        <label>Imagen</label>
        <input 
          type="file" 
          onChange={handleImageChange} 
          className="form-control-file" 
        />
        {tip.imageUrl && (
          <img 
            src={tip.imageUrl} 
            alt="Preview" 
            style={{maxWidth: '200px', marginTop: '10px'}} 
          />
        )}
      </div>

      <div className="form-group">
        <label>Fecha de Publicación</label>
        <input 
          type="date" 
          name="publishDate" 
          value={tip.publishDate} 
          onChange={handleChange} 
          className="form-control" 
          required 
        />
      </div>

      <div className="form-group">
        <label>Título Meta (SEO)</label>
        <input
          type="text"
          name="metaTitle"
          value={seoData.metaTitle}
          onChange={handleSeoChange}
          className="form-control"
          maxLength="60"
        />
      </div>

      <div className="form-group">
        <label>Descripción Meta (SEO)</label>
        <textarea
          name="metaDescription"
          value={seoData.metaDescription}
          onChange={handleSeoChange}
          className="form-control"
          maxLength="160"
        />
      </div>

      <button type="submit" className="btn btn-primary">
        {id ? 'Actualizar' : 'Crear'} Consejo
      </button>
    </form>
  );
};

export default TipForm;