import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HiddenAdminAccess = () => {
  const [keySequence, setKeySequence] = useState('');
  const [shiftPressed, setShiftPressed] = useState(false);
  const navigate = useNavigate();
  const SECRET_KEY = 'BBQADMIN';

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Shift') {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Shift') {
        setShiftPressed(false);
        setKeySequence('');
      }
    };

    const handleKeyPress = (e) => {
      if (shiftPressed) {
        const newSequence = keySequence + e.key.toUpperCase();
        setKeySequence(newSequence);

        if (newSequence.length > SECRET_KEY.length) {
          setKeySequence(newSequence.slice(-SECRET_KEY.length));
        }

        if (newSequence.endsWith(SECRET_KEY)) {
          navigate('/admin-login');
          setKeySequence('');
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [keySequence, shiftPressed, navigate]);

  return null;
};

export default HiddenAdminAccess;