import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { recipeService } from '../../services/recipeService';
import { Card, Button, Row, Col, Pagination, Form, Container } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

const RecipeList = () => {
  const [recipes, setRecipes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recipesPerPage, setRecipesPerPage] = useState(10);
  const [totalRecipes, setTotalRecipes] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      await fetchRecipes();
      const categoriasData = recipeService.getCategories();
      setCategories(categoriasData);
      setLoading(false);
    };

    loadInitialData();
  }, [currentPage, recipesPerPage, selectedCategory, selectedSubcategory]);
  
  const formatIngredients = (recipe) => {
    if (!recipe.ingredients) return [];
    
    return recipe.ingredients.map(ing => {
      if (typeof ing === 'string') {
        return {
          quantity: '',
          unit: '',
          name: ing
        };
      }
      return ing;
    });
  };

  const fetchRecipes = async () => {
    try {
      const result = await recipeService.getPaginatedRecipes(
        currentPage,
        recipesPerPage,
        searchTerm,
        selectedCategory,
        selectedSubcategory
      );
      // Formateamos los ingredientes de cada receta
      const formattedRecipes = result.recipes.map(recipe => ({
        ...recipe,
        ingredients: formatIngredients(recipe)
      }));
      setRecipes(formattedRecipes);
      setTotalRecipes(result.total);
    } catch (error) {
      console.error("Error fetching recipes:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta receta?')) {
      try {
        setLoading(true);
        await recipeService.deleteRecipe(id);
        await fetchRecipes();
      } catch (error) {
        console.error("Error deleting recipe:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRecipesPerPageChange = (e) => {
    setRecipesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubcategory('');
    setCurrentPage(1);
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
    setCurrentPage(1);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setLoading(true);
    await fetchRecipes();
    setLoading(false);
  };

  return (
    <Container className="recipe-list py-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Gestión de Recetas</h2>
        <Link to="/admin/recipe/new" className="btn btn-primary">
          Añadir Nueva Receta
        </Link>
      </div>

      <Form onSubmit={handleSearch} className="mb-4">
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Buscar recetas..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Select value={selectedCategory} onChange={handleCategoryChange}>
                <option value="">Todas las categorías</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Select
                value={selectedSubcategory}
                onChange={handleSubcategoryChange}
                disabled={!selectedCategory}
              >
                <option value="">Todas las subcategorías</option>
                {selectedCategory &&
                  categories
                    .find(cat => cat.id === selectedCategory)
                    ?.subcategories.map(subcat => (
                      <option key={subcat.id} value={subcat.id}>
                        {subcat.name}
                      </option>
                    ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Button type="submit" variant="primary" className="w-100">
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>

      {loading ? (
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      ) : recipes.length === 0 ? (
        <div className="text-center p-5">
          <p className="mb-0">No se encontraron recetas.</p>
        </div>
      ) : (
        <Row>
          {recipes.map((recipe) => (
            <Col key={recipe.id} md={6} lg={4} className="mb-4">
              <Card className="h-100">
                <LazyLoad height={200} once>
                  {recipe.mainImage && (
                    <Card.Img
                      variant="top"
                      src={recipe.mainImage.url}
                      alt={recipe.title}
                      style={{ height: '200px', objectFit: 'cover' }}
                    />
                  )}
                </LazyLoad>
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{recipe.title}</Card.Title>
                  <Card.Text>{recipe.description.substring(0, 100)}...</Card.Text>
                  <Card.Text>
                    <small className="text-muted">
                      <b>Categoría:</b> {categories.find(cat => cat.id === recipe.category)?.name}
                      <br />
                      <b>Creada:</b> {new Date(recipe.createdAt.seconds * 1000).toLocaleDateString()}
                      <br />
                      <b>Publicada:</b> {recipe.publishDate ? new Date(recipe.publishDate).toLocaleDateString() : 'No publicada'}
                    </small>
                  </Card.Text>
                  <div className="mt-auto">
                    <div className="d-flex justify-content-between align-items-center">
                      <Link 
                        to={`/admin/recipe/${recipe.id}`} 
                        className="btn btn-outline-primary btn-sm"
                      >
                        Editar
                      </Link>
                      <Button 
                        variant="outline-danger" 
                        size="sm" 
                        onClick={() => handleDelete(recipe.id)}
                      >
                        Eliminar
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      <div className="d-flex flex-wrap justify-content-between align-items-center mt-4">
        <Pagination className="mb-3">
          {[...Array(Math.ceil(totalRecipes / recipesPerPage))].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>

        <Form.Select
          className="mb-3"
          style={{ width: 'auto' }}
          value={recipesPerPage}
          onChange={handleRecipesPerPageChange}
        >
          {[10, 20, 50, 100].map(number => (
            <option key={number} value={number}>
              Mostrar {number} por página
            </option>
          ))}
        </Form.Select>
      </div>
    </Container>
  );
};

export default RecipeList;