import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1">
        <div className={isAdminRoute ? 'admin-container' : ''}>
          {children}
        </div>
      </main>
      {!isAdminRoute && <Footer />}
    </div>
  );
};

export default Layout;