// Blog.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getAllPosts } from '../../services/postService';
import './Blog.css';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const fetchedPosts = await getAllPosts();
        const madridDate = new Date().toLocaleString('en-US', { 
          timeZone: 'Europe/Madrid' 
        });
        const today = new Date(madridDate).toISOString().split('T')[0];
        
        const publishedPosts = fetchedPosts.filter(post => {
          return post.publishDate && post.publishDate <= today;
        });
        const sortedPosts = publishedPosts.sort((a, b) => 
          b.publishDate.localeCompare(a.publishDate)
        );
        setPosts(sortedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, []);

  const getPostRoute = (post) => {
    if (post.collection === 'recipes') return `/receta/${post.slug}`;
    if (post.collection === 'tips') return `/consejo/${post.slug}`;
    return '/blog';
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  return (
    <>
      <Helmet>
        <title>Blog de Barbacoa - Descubre el Arte del BBQ</title>
        <meta 
          name="description" 
          content="Explora nuestro blog especializado en barbacoa: recetas, consejos, técnicas y historia del BBQ. Todo lo que necesitas saber sobre el arte de la parrilla." 
        />
      </Helmet>

      <div className="blog-hero">
        <div className="blog-hero-overlay">
          <Container>
            <h1>Blog de Barbacoa</h1>
            <p className="hero-text">
              Descubre los secretos, técnicas y tradiciones del mundo del BBQ
            </p>
          </Container>
        </div>
      </div>

      <Container className="blog-content">
        <Row className="justify-content-center">
          {loading ? (
            <div className="text-center">Cargando posts...</div>
          ) : (
            posts.map((post) => (
              <Col key={post.id} md={4} className="mb-4">
                <Link to={getPostRoute(post)} className="post-link">
                  <Card className="post-card h-100">
                    {(post.mainImage || post.image) ? (
                      <Card.Img 
                        variant="top" 
                        src={(post.mainImage || post.image).url} 
                        alt={post.title}
                        className="post-image" 
                      />
                    ) : (
                      <div className="post-placeholder-image">
                        <span>BBQ</span>
                      </div>
                    )}
                    <Card.Body>
                      <Card.Title>{post.title}</Card.Title>
                      <div className="post-meta">
                        <span className="post-date">
                          {formatDate(post.publishDate)}
                        </span>
                        <span className="post-type">
                          {post.collection === 'recipes' ? 'Receta' : 'Consejo'}
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))
          )}
        </Row>

        <section className="newsletter-section mt-5">
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="newsletter-container text-center">
                <h3>¿Quieres más contenido sobre BBQ?</h3>
                <p>Suscríbete a nuestro newsletter y recibe las últimas actualizaciones</p>
                <form className="newsletter-form">
                  <input type="email" placeholder="Tu correo electrónico" required />
                  <button type="submit">Suscribirse</button>
                </form>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default Blog;