import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebookF, FaPinterestP } from 'react-icons/fa';
import './footer.css'

const Footer = () => {
  return (
    <footer className="bbq-footer">
      <Container>
        <Row>
          <Col md={4}>
            <h5>Sobre The Original Rub Hub</h5>
            <p>Tu fuente definitiva para conocer la historia y el arte del BBQ. Descubre las tradiciones, técnicas y secretos que hacen única la cultura de la barbacoa.</p>
          </Col>
          <Col md={4}>
            <h5>Enlaces rápidos</h5>
            <ul className="list-unstyled">
              <li><Link to="/">Historia BBQ</Link></li>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/nosotros">Sobre nosotros</Link></li>
              <li><Link to="/contacto">Contacto</Link></li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Síguenos</h5>
            <div className="social-icons">
              <a href="https://www.instagram.com/bbqmasters" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              <a href="https://www.facebook.com/bbqmasters" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
              <a href="https://www.pinterest.com/bbqmasters" target="_blank" rel="noopener noreferrer"><FaPinterestP /></a>
            </div>
          </Col>
        </Row>
        <hr />
        <p className="text-center">&copy; 2024 The Original Rub Hub. Todos los derechos reservados.</p>
      </Container>
    </footer>
  );
};

export default Footer;